/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from 'react';
import { useDispatch,useSelector} from "react-redux";
import { useCookies } from 'react-cookie';

import { setGenerateTab } from '../../redux/makeTable';
import {setButtonOkLoad,setModalsProps} from '../../redux/modals';
import {setOutputTitle,setPropsOutput} from '../../redux/customOutput';
import { setTheOutput,setOutputKind, setActionToDo, 
    setInProgressCata} from '../../redux/portal';


import ModalWarn from './MainContantComponents/ModalsComponents/ModalWarning'
import ModalCurrency from './MainContantComponents/ModalsComponents/ModalCurrency'
import ModalCustom from './MainContantComponents/ModalsComponents/ModalCustom'
import ModalFilter from './MainContantComponents/ModalsComponents/ModalFilter'
import ModalGenerate from './MainContantComponents/ModalsComponents/ModalGenerate'
import ModalSplit from './MainContantComponents/ModalsComponents/ModalSplit';
import ModalRename from './MainContantComponents/ModalsComponents/ModalRename';

import TableContant from './MainContantComponents/TableComp';
import MakeTable from './MakeTableCom';
import OutputMenuCom from './OutputMenuCom';

import '../../loading.css'
import '../../scroll.css'
import { ModalAllOutput } from './MainContantComponents/ModalsComponents/ModalAllOutput';
import { RepoDataComp } from './MainContantComponents/RepoComps';
import { AllSet, ApiCall, CurrencyDetails, defultOkButton, getMulValues, getTabDetails, getUserPath, getValue, pythonUrl, repoTab, summarised_yearly_clients } from '../../Data/Variables';
import ModelBudget from './MainContantComponents/ModalsComponents/ModelBudget';
import ModalYears from './MainContantComponents/ModalsComponents/ModalYears';


const MainContant = (props) =>
{
    const { theCatalog,theOutput,tabMenuList} = useSelector((state) => state.portal);
    const { email } = useSelector((state) => state.frame);

    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')
    const { catalogDetails,actionToDo,outputKind,inProgressCata } = useSelector((state) => state.portal);
    const { buttonOkLoad,modalsProps } = useSelector((state) => state.modals);
    const { outputTitle,propsOutput } = useSelector((state) => state.customOutput);
    const {OrigRenme,tabtofolderdraft,tabtofolder} = useSelector((state) => state.globalVars);

    const dispatch = useDispatch();

    const [tables,setTheTables] = useState(false);
    const [loaderGap,setLoaderGap] = useState(false);

    const [dropDownDownload,SetDropDownload]=useState(false);
    const [repoDownloadText,setRepoDownloadText]=useState("Download this folder/file");

    const [isFullScreen,SetIsFullScreen] = useState(false);

    const [isPivot,setIsPivot] = useState(false);
    const [clickedPivot,setClickedPivot]=useState(false);
    const [packTabs,setPacksTabs]=useState([]);

    const [Summarised_Year,setSummarisedYear] = useState(false);

    const [yearStatement,setYearStatement]=useState(undefined);
    const [yearStatementTarget,setYearStatementTarget]=useState(undefined);
    const [, setCookies, ] = useCookies();

    const [incomeTabPack, setIncomeTabPack] = useState();

    const downloadOutput = async (tab,jwt,outputName) =>
    {   
        if(output[1] !== repoTab)
        {
            dispatch(setActionToDo(""));
            let isSpecial = (outputName in OrigRenme[theCatalog]);
            dispatch(setButtonOkLoad({...buttonOkLoad, [isSpecial ? "DownloadStandard" : "Download"] : true}));
            if(!(isPivot && clickedPivot))
            {   
                let params = 
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                    body: new URLSearchParams(
                    {
                        "catalog_id" : catalogDetails[theCatalog]["_id"],
                        "theTab" : tab,
                        "tabKind" :  isSpecial ? tabtofolder[OrigRenme[theCatalog][outputName]] : "normal",
                        "userPath": getUserPath("",email,props.clientName,OrigRenme,theCatalog,outputName,tabtofolderdraft),
                        "clientName" : props.clientName,
                        "splitTableby" : JSON.stringify(outputTitle["split"]),
                        "currencyMode" : getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed"),
                        "currencyValue" : 1/outputTitle["currencies"][theCatalog][outputTitle["currencyKey"][theCatalog].toLowerCase()],
                        "currency" : outputTitle["currencyKey"][theCatalog],
                        "AllOutput" : getValue(AllSet,outputName,""),
                        "yearsRange" : JSON.stringify([outputTitle["YearsFrom"],outputTitle["YearsTo"]]),
                        "packs" : JSON.stringify(packTabs),
                        "yearStatementCurr" : yearStatement,
                        "yearStatementTarrget" : yearStatementTarget,
                        'summarised' : Summarised_Year
                    })
                }
                let rasp = await ApiCall(pythonUrl+"/DownloadCatalogOutput",params,setCookies,(err) => 
                {
                    console.log(err)
                    alert("Data Error. Please contact us at data@bitsonic.ai");
                    dispatch(setButtonOkLoad({...buttonOkLoad, [isSpecial ? "DownloadStandard" : "Download"] : false}));
                });
                const blob = await rasp.blob();
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", (isSpecial ? (tab === "" ? theCatalog+"_"+outputName : tab) : outputName) + ".xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch(setButtonOkLoad({...buttonOkLoad, [isSpecial ? "DownloadStandard" : "Download"] : false}));
            }
            else
            {
                let params = 
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                    body: new URLSearchParams({
                        "catalog_id" : catalogDetails[theCatalog]["_id"],
                        "userPath" : email
                    })
                }
                let rasp = await ApiCall(pythonUrl+"/DownloadPivot",params,setCookies,(err) => 
                {
                    console.log(err)
                    alert("Data Error. Please contact us at data@bitsonic.ai");
                    dispatch(setButtonOkLoad({...buttonOkLoad, [isSpecial ? "DownloadStandard" : "Download"] : false}));
                });
                const blob = await rasp.blob();
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", "Pivotfile.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch(setButtonOkLoad({...buttonOkLoad, "Download" : false}));
            }
        }
    }

    useEffect(() => 
    {
        if(actionToDo.startsWith("GetOutput"))
        {
            let valTarget = yearStatementTarget;
            let valCurr = yearStatement;
            if(actionToDo === "GetOutput")
            {
                valTarget = theCatalog === "" && outputName in OrigRenme[theCatalog] ? "Half" : getTabDetails(tabMenuList,theCatalog,outputName,"YearPeriodInterval",tabtofolderdraft,OrigRenme[theCatalog]);
                valCurr = valTarget;
                setYearStatement(valCurr);
                setYearStatementTarget(valTarget);
            }
            
            let tempisPivot = isPivot;
            if(isPivot && valTarget !== valCurr)
            {
                tempisPivot = false;
                setIsPivot(false);
            }

            setGenerateTab(true);
            if(clickedPivot)
                setClickedPivot(false);

            let split = []
            let YearsFrom = "";
            let YearsTo = "";
            if(actionToDo === "GetOutput")
                dispatch(setOutputTitle({...outputTitle,"split" : split,"YearsFrom" : YearsFrom,"YearsTo" : YearsTo}));
            else
            {
                split = outputTitle["split"];
                YearsFrom = outputTitle["YearsFrom"];
                YearsTo = outputTitle["YearsTo"];
            }
            dispatch(setActionToDo(""))
            if(theCatalog !== undefined)
            {
                let tabname = outputName;
                if(outputKind !== undefined && outputKind !== "")
                    tabname = outputKind;
                
                setLoaderGap(true);
                setTheTables(<></>);
                let isSpecial = outputName in OrigRenme[theCatalog];
                console.log( JSON.stringify([YearsFrom,YearsTo]))
                const getCatalog = async (catalogDetails,theCatalog,tabname,outputTitle,output,defultOkButton,modalsProps,isPivot,outputKind,propsOutput,jwt) =>
                {
                    let params = 
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                        body: new URLSearchParams(
                        {
                            "catalog_id" : catalogDetails[theCatalog]["_id"],
                            "theTab" : tabname,
                            "tabKind" :  isSpecial ? tabtofolder[OrigRenme[theCatalog][outputName]] : "normal",
                            "splitTableby" : JSON.stringify(split),
                            "AllOutput" : getValue(AllSet,outputName,""),
                            "userPath": getUserPath("",email,props.clientName,OrigRenme,theCatalog,outputName,tabtofolderdraft),
                            "clientName" : props.clientName,
                            "currencyMode" : getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed"),
                            "currency": 1/outputTitle["currencies"][theCatalog][outputTitle["currencyKey"][theCatalog].toLowerCase()],
                            "currencyKey" : outputTitle["currencyKey"][theCatalog],
                            "yearStatementCurr" : valCurr,
                            "yearsRange" : JSON.stringify([YearsFrom,YearsTo]),
                            "yearStatementTarrget" : valTarget,
                            'summarised' : Summarised_Year
                        })
                    }
                    let data = await ApiCall(pythonUrl+"/GetCatalogOutput",params,setCookies,(err) => 
                    {
                        alert(err);
                        dispatch(setButtonOkLoad(defultOkButton));
                    });
                    data = await data.json();
                    console.log(tabtofolder[OrigRenme[theCatalog][outputName]] )
                    
                    //setTheTables(<></>);
                    let tables = []
                    for(let i=0;i<data.length;i++)
                    {
                        tables.push(<React.Fragment key={i}>
                                        <MakeTable index={i} isPins={tabtofolder[OrigRenme[theCatalog][outputName]] !== "BudgetDetails"} 
                                                tableLen={data.length} setLoaderGap={setLoaderGap} clientName={props.clientName} 
                                                spotiSongList={getValue(props.spotiSongList,theCatalog,{})} isPivot={tempisPivot} 
                                                dataDir={theCatalog !== undefined ? catalogDetails[theCatalog]["_id"] : ""} 
                                                tableData={data[i]} tableName={tabname} outputKind={outputKind}
                                                jwt={props.jwt} isNotTitleCount={true}/>
                                    </React.Fragment>);
    }
                    
                    dispatch(setButtonOkLoad(defultOkButton));     
                    dispatch(setModalsProps({...modalsProps,"modalCurrency" : false,"modalSplit" : false,"modalFilter" : false,"modalYears" : false}));
                    setTheTables(tables);
                    if(outputName in OrigRenme[theCatalog] && outputKind === "1")
                        dispatch(setTheOutput(theCatalog+"_Overview"));

                     dispatch(setPropsOutput({...propsOutput,"split" : []}));
                }
                getCatalog(catalogDetails,theCatalog,tabname,outputTitle,output,defultOkButton,modalsProps,isPivot,outputKind,propsOutput,props.jwt);
            }
            else
            {
                alert("Choose Catalog")
                dispatch(setActionToDo("ClearTable"));
            }
            
        }
        if(actionToDo === "ClearTable")
        {
            dispatch(setActionToDo("ClearTableNext"));
            dispatch(setTheOutput(""));
            dispatch(setOutputKind("normal"));
            setTheTables(<></>);
            setLoaderGap(true);
        }
        if(actionToDo === "In-ProgressCatalog")
        {
            dispatch(setActionToDo(""));
            dispatch(setInProgressCata(true));
            setTheTables(
            <article className="message is-warning is-large fullHeight">
                <div className="message-body fullHeight ">
                    {props.catalogMenuList[theCatalog].currentStatus === "in-progress" && props.catalogMenuList[theCatalog].hasFrame 
                    ?
                        <>
                            This catalogue is still <b>In Progress</b>.<br/>
                            It will appear here when the analysis is complete.
                        </>
                    :
                    !props.catalogMenuList[theCatalog].hasFrame 
                    ? 
                        <>
                            This catalogue is <b>Disabled</b> right now.<br/>
                            It will appear here when the analysis is complete.
                        </>
                    :
                        <>
                            This catalogue is <b>Ready for Release</b>.<br/>
                            However it seems that the BitSonic team has not approved the Release.<br/>
                            It will appear here when BitSonic have approved the Analysis.
                        </>
                    }
                </div>
            </article>
            );
            setLoaderGap(false);
        }
        if(actionToDo === "DownloadStandard")
        {
            dispatch(setActionToDo(""));
            downloadOutput("",props.jwt,outputName);
        }
        if(actionToDo === "DownloadOutput")
        {
            downloadOutput((outputName in OrigRenme[theCatalog]) ? outputKind : outputName,props.jwt,outputName);
            SetDropDownload(false);
        }
        if(actionToDo.startsWith("pivotPaging_"))
        {
            dispatch(setActionToDo(""));
            let ary = actionToDo.split("_");
            console.log(tables)
            setTheTables([tables[parseInt(ary[1])]]);
            setLoaderGap(false);
        }
        if(actionToDo.startsWith("pivotActive"))
            setClickedPivot(true);
        if(actionToDo.startsWith("GetRepo"))
        {
            setTheTables(<RepoDataComp jwt={props.jwt} setRepoDownloadText={setRepoDownloadText}/>);
            dispatch(setActionToDo(""));
        }
        if(actionToDo.startsWith("AddOutput"))
            setSummarisedYear(false);
        

    },[actionToDo])
    useEffect(()=>
    {
        let tabsTemp = theCatalog !== undefined ? Object.keys(getMulValues(tabMenuList,[theCatalog,tabtofolderdraft[OrigRenme[theCatalog][outputName]],"tabs"],{})) : [];
        setPacksTabs(tabsTemp);
    },[theOutput])

    return (<div className='box' style={{"height" : "100%"}}>
                <ModalWarn />
                <ModalAllOutput clientName={props.clientName} catalogStatus={props.catalogStatus}/>
                <OutputMenuCom packs={props.packs} jwt={props.jwt} clientName={props.clientName} incomeTabPack={incomeTabPack} setIncomeTabPack={setIncomeTabPack}/>
                {theCatalog !== undefined ?
                    <center>
                        {/*Buttons Bar*/}
                        <div className={inProgressCata ?'buttons is-vcentered is-marginless is-gapless is-narrow is-vcentered disabledButtons' : 'buttons is-vcentered is-marginless is-gapless is-narrow is-vcentered'} >
                            {/*Download Output*/}
                            <div className={dropDownDownload ? "dropdown is-active mr-2 mb-2" : "dropdown mr-2 mb-2"} onBlur={() => SetDropDownload(false)}>
                                <div className="dropdown-trigger">
                                    <button className={buttonOkLoad["Download"] || buttonOkLoad["DownloadStandard"] ? 'button is-small is-loading is-marginless' : 'button is-small is-marginless'} onClick={() => SetDropDownload(!dropDownDownload)}  disabled={buttonOkLoad["Download"] || buttonOkLoad["DownloadStandard"]} aria-haspopup="true" aria-controls="dropdown-menu4">
                                        <span className="icon"><i className="fa-solid fa-download"/></span>
                                        <span>Download</span>
                                        <span className="icon is-small"> <i className={dropDownDownload ? "fas fa-angle-up" : "fas fa-angle-down"} aria-hidden="true"></i></span>
                                    </button>
                                </div>
                                <div className="dropdown-menu " >
                                    <div className="dropdown-contant dropDownDownloadStyle">
                                        <a className="dropdown-item" onMouseDown={() => {dispatch(setActionToDo("DownloadOutput"))}}>{ output[1] === repoTab ? repoDownloadText : "Download this tab"}</a>
                                        <hr className="dropdown-divider is-marginless"/>
                                        {(outputName in OrigRenme[theCatalog]) && OrigRenme[theCatalog][outputName] !== "Overview" && !(isPivot && clickedPivot) ?
                                            <a className={buttonOkLoad["DownloadStandard"] ? 'dropdown-item is-small' : 'dropdown-item is-small'} onMouseDown={() => {SetDropDownload(false);dispatch(setActionToDo("DownloadStandard"));}}>Download All Standard Outputs</a>
                                        :null}
                                    </div>
                                </div>
                            </div>
                      
                            {/*Currency*/}
                            {((theCatalog === "" ) || (outputName in OrigRenme[theCatalog] && !tabtofolderdraft[OrigRenme[theCatalog][outputName]].includes("Draft"))) && output[1] !== 'Budget' ? 
                            <button className='button is-small'
                                onClick={() => {dispatch(setModalsProps({...modalsProps,"modalCurrency" : true}));dispatch(setPropsOutput({...propsOutput,"currencyKey" : outputTitle["currencyKey"],"currencies" : outputTitle["currencies"]}))}}>
                                <span className="icon">
                                    <i className="fa-solid fa-coins"/>
                                </span><span>Output Currency: <span className='tag is-rounded'>{outputTitle["currencyKey"][theCatalog]}</span></span>
                            </button> : null}
                            <ModalCurrency clientName={props.clientName} packs={props.packs}/>
                            {outputName !== "Overview" ? <>
                              
                                    {/*Split*/}{(output[1] !== repoTab && (theCatalog !== "" || !(outputName in OrigRenme[theCatalog])) && (outputKind === "" || (!(outputKind.startsWith("Payor") || outputKind === "Song x IncomeType x Rev x Prd")))) ? <button className='button is-small' onClick={() => {dispatch(setModalsProps({...modalsProps,"modalSplit" : true}));}}><span className="icon"><i className="fa-solid fa-table-list"/></span><span>Split Table</span></button> : <></>}
                                    {/*Split*/}{(output[1] !== repoTab && (theCatalog !== "" || !(outputName in OrigRenme[theCatalog]))) ? <button className='button is-small' onClick={() => {dispatch(setModalsProps({...modalsProps,"modalYears" : true}));}}><span className="icon"><i className="fa-solid fa-calendar-days"/></span><span>Set Years</span></button> : <></>}

                                    {/*FullScreen*/}<button className='button is-small' disabled={theOutput === ""} onClick={() => {SetIsFullScreen(true);dispatch(setActionToDo("pivot_"+(isPivot)))}}><span className="icon"><i className="fa-solid fa-up-right-and-down-left-from-center"/></span><span>Full Screen</span></button>
                                
                                    {/*Filter*/}
                                    {(theCatalog !== "") && (output[1] !== repoTab) && (outputName !== "Overview" && (outputName in OrigRenme[theCatalog])) ? <button className='button is-small' disabled={theOutput === ""} onClick={() => dispatch(setModalsProps({...modalsProps,"modalFilter" : true}))}><span className="icon"><i className="fa-solid fa-filter"/></span><span>Filters</span></button> : <></>}

                                    {/*Pivot Switch*/}
                                    {outputName !== "Overview" && (output[1] !== repoTab) && output[1] !== 'Budget' ?  
                                        <div className="field mb-2 ml-3 is-small mr-4">
                                            <input id="switchExample3" type="checkbox" className="button switch is-normal" onChange={() => 
                                                {
                                                    if(yearStatement === yearStatementTarget)
                                                    {
                                                        setIsPivot(!isPivot);
                                                        dispatch(setActionToDo("pivot_"+(!isPivot)));
                                                    }
                                                    else
                                                        alert("Cannot use pivot when statement has changed.")
                                                }} checked={isPivot}/>
                                            <label htmlFor="switchExample3">Pivot</label>
                                        </div>   
                                    : <></>}
                        
                                <>
                                    { (output[1] !== repoTab) && output[1] !== 'Budget' ? 
                                    <>
                                        <div className="buttons has-addons  mb-2">
                                            <button className={"button is-small m-0"+(yearStatementTarget === "Year"     ? " is-info" : "" )} onClick={() => {setYearStatementTarget("Year");dispatch(setActionToDo("GetOutputState"))}}>Year</button>
                                            <button className={"button is-small m-0"+(yearStatementTarget === "Half"     ? " is-info" : "" )} onClick={() => {setYearStatementTarget("Half");dispatch(setActionToDo("GetOutputState"))}}>Half</button>
                                            <button className={"button is-small m-0"+(yearStatementTarget === "Quarter"  ? " is-info" : "" )} onClick={() => {setYearStatementTarget("Quarter");dispatch(setActionToDo("GetOutputState"))}}>Quarter</button>
                                            <button className={"button is-small m-0"+(yearStatementTarget === "Month"    ? " is-info" : "" )} onClick={() => {setYearStatementTarget("Month");dispatch(setActionToDo("GetOutputState"))}}>Month</button>
                                        </div>
                                        {summarised_yearly_clients.includes(props.clientName) ?
                                            <div className="field mb-2 ml-3 is-small mr-4">
                                                <input id="summarised_yearly" type="checkbox" className="button switch is-normal" checked={Summarised_Year} 
                                                    onChange={() => 
                                                        {
                                                            setSummarisedYear(!Summarised_Year)
                                                            dispatch(setActionToDo("GetOutputSummarised"));
                                                        }}
                                                />
                                                <label htmlFor="summarised_yearly">Sub Total</label>
                                            </div>
                                        : null}
                                    </>
                                    : null
                                    }
                                </>

                                </> : 
                                
                                <>{/*FullScreen*/}
                                    <button className='button is-small' disabled={theOutput === ""} onClick={() => {SetIsFullScreen(true);dispatch(setActionToDo("pivot_"+(isPivot)))}}><span className="icon"><i className="fa-solid fa-up-right-and-down-left-from-center"/></span><span>Full Screen</span></button>
                                    {summarised_yearly_clients.includes(props.clientName) && theCatalog !== ""
                                    // && budget_users.includes(email)
                                    ?
                                        <button className='button is-small'  disabled={theOutput === ""} onClick={() => dispatch(setModalsProps({...modalsProps,"budgetModel" : true}))}><span className="icon"><i className="fa-solid fa-pen-to-square"/></span><span>Edit Budget Tracking</span></button>
                                    :<></>}
                                </>
                            }

                            {//<button className={outputName === "Standard Outputs" || outputName === "Overview" || theOutput === "" ? "button is-small is-invisible	" : buttonOkLoad["DeleteOutput"] ? 'button is-loading' : 'button is-small'}  onClick={() => {deleteOutput();dispatch(setActionToDo("DeleteOutput"))}} ><span className="icon"><i className="fa-solid fa-trash"/></span><span>Delete Output</span></button>
                            }
                        </div>
                    </center>
                : <></>
                }
                { isFullScreen ?
                    <div className = { isFullScreen ? "modal is-active" : "modal" } style={{"zIndex" : 20}} >
                        <div className="modal-background">
                            <div className='m-4 fullHeight' style={{"overflowY" : "scroll"}}>
                                <TableContant packs={props.packs} tables={tables} loaderGap={loaderGap} outputKind={outputName} output={output} precent={theCatalog !== undefined && (outputName in OrigRenme[theCatalog] || theCatalog === "") ? "90%" : "91%"} delButton={<div className=''><button className="delete is-pulled-right is-medium" onClick={() => {SetIsFullScreen(false);dispatch(setActionToDo("pivot_"+(isPivot)));}}></button></div>}/>                                    
                            </div>
                        </div>
                    </div>  
                    :
                
                    <TableContant packs={props.packs} tables={tables} loaderGap={loaderGap} output={outputName} precent={theCatalog !== undefined && (outputName in OrigRenme[theCatalog] || theCatalog === "") ? "84%" : "83%"} delButton={null} /> 
                }
                <ModalSplit />
                <ModalYears filterValues={props.filterValues} />
                {theCatalog !== undefined ?
                    <ModalFilter filterValues={props.filterValues} jwt={props.jwt} validCatalogs={props.validCatalogs} catalogMenuList={props.catalogMenuList}/>
                : <></>}
                <ModalCustom  
                    className={props.className} packs={props.packs} spotiSongList={getValue(props.spotiSongList,theCatalog,{})} setYearStatementTarget={setYearStatementTarget}
                    setTheTables={setTheTables} setLoaderGap={setLoaderGap} clientName={props.clientName} isPivot={isPivot} jwt={props.jwt} setYearStatement={setYearStatement}
                />
                
                <ModalGenerate jwt={props.jwt}
                    clientName={props.clientName}
                    packs={props.packs}
                />

                <ModalRename />
                {modalsProps['budgetModel'] ?
                    <ModelBudget  jwt={props.jwt} incomeTabPack={incomeTabPack} clientName={props.clientName}/> 
                : null}
                
            </div>);
}
export default MainContant;
