/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import Select from 'react-select'
import { useDispatch,useSelector} from "react-redux";

import { setModalsProps,setButtonOkLoad } from '../../../../redux/modals';
import { setOutputTitle, setPropsOutput } from '../../../../redux/customOutput';
import { setActionToDo } from '../../../../redux/portal';

import { getMulValues } from '../../../../Data/Variables';

const ModalSplit = ({filterValues}) =>
{

    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const { theCatalog } = useSelector((state) => state.portal);
    const { propsOutput,outputTitle } = useSelector((state) => state.customOutput);
    const dispatch = useDispatch();


    return(<center>
        <div className={modalsProps["modalYears"] ? "modal is-active" : "modal"} style={{"zIndex" : 30}}> 
        <div className="modal-background"></div>
        <div className="modal-card" >
        <header className="modal-card-head">
            <p className="modal-card-title">Catalogue Settings</p>
            <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalYears" : false}));dispatch(setPropsOutput({...propsOutput,"YearsFrom" : outputTitle["YearsFrom"],"YearsTo" : outputTitle["YearsTo"]}))}}></button>
        </header>
        <section className="modal-card-body">
            <p className='is-size-6'><b>Set Years:</b></p>

            <p className='is-size-6'>From:</p>
            <Select onChange={(dict) =>{dispatch(setPropsOutput({...propsOutput,"YearsFrom" : dict["value"]}));}} 
                                value={{"value" : propsOutput["YearsFrom"],"label" : propsOutput["YearsFrom"]}} 
                                options={getMulValues(filterValues,[theCatalog,"Period"],[]).map((val) => {return {value : val,label : val}})}    
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}/>

            <p className='is-size-6'>To:</p>
            <Select onChange={(dict) =>{dispatch(setPropsOutput({...propsOutput,"YearsTo" : dict["value"]}));}} 
                                value={{"value" : propsOutput["YearsTo"],"label" : propsOutput["YearsTo"]}} 
                                options={getMulValues(filterValues,[theCatalog,"Period"],[]).map((val) => {return {value : val,label : val}})}    
            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}/>
            
        </section>
        <footer className="modal-card-foot">
            <div className='fullwidth'>
                <div className='is-pulled-right'>
                    <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalYears" : false}));dispatch(setPropsOutput({...propsOutput,"YearsFrom" : outputTitle["YearsFrom"],"YearsTo" : outputTitle['YearsTo']}))}}>Cancel</button>
                    <button className={buttonOkLoad["GetOutput"] ? "button is-success is-loading" : "button is-success"} 
                            onClick={() => {dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : true})); 
                                            dispatch(setOutputTitle({...outputTitle,"YearsFrom" : propsOutput["YearsFrom"],"YearsTo" : propsOutput['YearsTo']}));
                                            dispatch(setActionToDo("GetOutputYears"))}}
                            disabled={propsOutput["YearsTo"] === "" || propsOutput["YearsFrom"] === ""}
                    >Save changes</button>
                </div>
            </div>
        </footer>
        </div>
        </div>
        </center>);

}
export default ModalSplit;